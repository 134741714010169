
export enum Locations {
    MainStage = "Main Stage",
    Werkelwinkel = "Werkelwinkel",
    BatikBucht = "Batik Bucht",
    PlutoPavillon = "Pluto Pavillon",
    Himmelszelt = "Himmelszelt",
    Spielwiese = "Spielwiese",
    Container = 'Container',
    Sternwarte = 'Sternwarte',
    Bauwagen = 'Bauwagen',
    SAPlaxie = 'SPAlaxie',

}

export enum GigType {
    Act = 'Act',
    Workshop = 'Workshop',
}

const timetable = [
    // Acts
    {
        "id": "0",
        "artistId": "14",
        "startAt": "2024-06-13T20:00:00",
        "endAt": "2024-06-13T22:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Luxus
    {
        "id": "1",
        "artistId": "2",
        "startAt": "2024-06-14T19:30:00",
        "endAt": "2024-06-14T20:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Pablo Froind
    { 
        "id": "2",
        "artistId": "7",
        "startAt": "2024-06-14T21:00:00",
        "endAt": "2024-06-14T22:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Peppermint Peanuts
    {
        "id": "3", 
        "artistId": "6",
        "startAt": "2024-06-14T22:30:00",
        "endAt": "2024-06-14T23:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Montezuma
    {
        "id": "4", 
        "artistId": "11",
        "startAt": "2024-06-14T23:30:00",
        "endAt": "2024-06-15T01:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Pole Dance
    {
        "id": "5", 
        "startAt": "2024-06-15T01:00:00",
        "endAt": "2024-06-15T01:30:00",
        "description": "Pole Dance Performance",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },
    // Joe Back
    {
        "id": "6", 
        "artistId": "1",
        "startAt": "2024-06-15T01:00:00",
        "endAt": "2024-06-15T02:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.Container,
        "type": GigType.Act,
    },
    // Cia
    {
        "id": "7", 
        "artistId": "5",
        "startAt": "2024-06-15T02:30:00",
        "endAt": "2024-06-15T04:00:00",
        "description": "",
        "openEnd": true,
        "location": Locations.Container,
        "type": GigType.Act,
    },
    // Constant Crisis
    {
        "id": "8", 
        "artistId": "15",
        "startAt": "2024-06-15T01:00:00",
        "endAt": "2024-06-15T04:00:00",
        "description": "",
        "openEnd": true,
        "location": Locations.Sternwarte,
        "type": GigType.Act,
    },

    // Samstag
    // Leroy Lovestein
    {
        "id": "9", 
        "artistId": "16",
        "startAt": "2024-06-15T11:00:00",
        "endAt": "2024-06-15T13:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Bella Biscuit
    {
        "id": "10", 
        "artistId": "12",
        "startAt": "2024-06-15T13:00:00",
        "endAt": "2024-06-15T15:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Choky 
    {
        "id": "11", 
        "artistId": "3",
        "startAt": "2024-06-15T18:00:00",
        "endAt": "2024-06-15T19:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Olympia
    {
        "id": "12", 
        "artistId": "8",
        "startAt": "2024-06-15T19:30:00",
        "endAt": "2024-06-15T20:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Urban Socks 
    {
        "id": "13", 
        "artistId": "17",
        "startAt": "2024-06-15T21:00:00",
        "endAt": "2024-06-15T22:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Elane
    {
        "id": "14", 
        "artistId": "10",
        "startAt": "2024-06-15T22:00:00",
        "endAt": "2024-06-15T23:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Sophti
    {
        "id": "15", 
        "artistId": "13",
        "startAt": "2024-06-15T22:30:00",
        "endAt": "2024-06-16T01:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    // Büse3000
    {
        "id": "16", 
        "artistId": "4",
        "startAt": "2024-06-16T01:00:00",
        "endAt": "2024-06-16T02:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.Container,
        "type": GigType.Act,
    },
    {
        "id": "17", 
        "artistId": "420",
        "startAt": "2024-06-16T02:30:00",
        "endAt": "2024-06-16T04:00:00",
        "description": "",
        "openEnd": true,
        "location": Locations.Container,
        "type": GigType.Act,
    },
    {
        "id": "18", 
        "artistId": "18",
        "startAt": "2024-06-16T01:00:00",
        "endAt": "2024-06-16T02:30:00",
        "description": "",
        "openEnd": false,
        "location": Locations.Sternwarte,
        "type": GigType.Act,
    },
    {
        "id": "19", 
        "artistId": "9",
        "startAt": "2024-06-16T12:00:00",
        "endAt": "2024-06-16T14:00:00",
        "description": "",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Act,
    },
    
    // Workshops
    // Speeddating
    {
        "id": "100",
        "startAt": "2024-06-13T19:00:00",
        "endAt": "2024-06-13T20:00:00",
        "description": "Auch dieses Jahr kommen auf der Tanzwiese die unterschiedlichsten Menschen und Gruppen  zusammen, um eine schöne, gemeinsame Zeit zu verbringen. Im Rahmen unseres Speeddatings, habt ihr die Möglichkeit euch in relativ kurzer Zeit, mit fast allen BesucherInnen der Tanzwiese auszutauschen und anzufreunden. Ob nur für ein Wochenende, oder über den Festivalkontext hinaus, lasst neue Freundschaften entstehen!",
        "name": "Speeddating",
        "openEnd": false,
        "location": Locations.Spielwiese,
        "type": GigType.Workshop,
    },
    // Speeddating 2
    {
        "id": "101",
        "startAt": "2024-06-14T18:00:00",
        "endAt": "2024-06-14T19:00:00",
        "description": "Auch dieses Jahr kommen auf der Tanzwiese die unterschiedlichsten Menschen und Gruppen  zusammen, um eine schöne, gemeinsame Zeit zu verbringen. Im Rahmen unseres Speeddatings, habt ihr die Möglichkeit euch in relativ kurzer Zeit, mit fast allen BesucherInnen der Tanzwiese auszutauschen und anzufreunden. Ob nur für ein Wochenende, oder über den Festivalkontext hinaus, lasst neue Freundschaften entstehen!",
        "name": "Speeddating II",
        "openEnd": false,
        "location": Locations.Spielwiese,
        "type": GigType.Workshop,
    },
    // Kino
    {
        "id": "102",
        "startAt": "2024-06-13T22:00:00",
        "endAt": "2024-06-13T00:00:00",
        "description": "",
        "name": "Kino",
        "openEnd": true,
        "location": Locations.Bauwagen,
        "type": GigType.Workshop,
    },
    // Yoga
    {
        "id": "103",
        "startAt": "2024-06-13T10:00:00",
        "endAt": "2024-06-13T11:00:00",
        "description": "",
        "name": "Morning Yoga",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },
    // Yoga 2
    {
        "id": "104",
        "startAt": "2024-06-14T10:00:00",
        "endAt": "2024-06-14T11:00:00",
        "description": "",
        "name": "Morning Yoga",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },
    // Yoga 3
    {
        "id": "105",
        "startAt": "2024-06-15T10:00:00",
        "endAt": "2024-06-15T11:00:00",
        "description": "",
        "name": "Morning Yoga",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },

    // Traumfänger
    {
        "id": "106",
        "startAt": "2024-06-14T15:00:00",
        "endAt": "2024-06-14T17:00:00",
        "description": "",
        "name": "Traumfänger Basteln",
        "openEnd": false,
        "location": Locations.PlutoPavillon,
        "type": GigType.Workshop,
    },

    // Kakao
    {
        "id": "107",
        "startAt": "2024-06-14T17:00:00",
        "endAt": "2024-06-14T18:30:00",
        "description": "Angefangen mit einer Yin Yoga Einheit möchte ich mich mit euch gemeinsam auf eine kleine Reise machen, die uns unseren Sinnen näher bringt.  Wir wollen uns gemeinsam erden, um unsere Verbindung zu uns zu stärken und anschließend eine Tasse Kakao genießen! ✨ Um gemeinsam eine absolute Wohlfühlatmosphäre und einen geschützten Rahmen zu kreieren, ist die Teilnehmerzahl auf 25 Personen begrenzt. Bitte über Telegram oder Instagram bei @as_mllr anmelden.🔮🫧 Mitzubringen sind eine Yoga-Matte und bequeme Kleidung!",
        "name": "Kakao Zeremonie",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },

    // Samstag
    // Traumreise
    {
        "id": "108",
        "startAt": "2024-06-15T11:00:00",
        "endAt": "2024-06-15T12:00:00",
        "description": "„Im Radio läuft ein Sender aus dem Land, in dem du dich gerade aus Freizeitgründen und ohne Sorgen jeglicher Art aufhältst. Du fährst mit deinem Auto sammelst direkt schöne Eindrücke des Ortes. Die Häuser auf deiner rechten Seite erinnern dich irgendwie an...“ Mache es dir gemütlich, atme tief durch und begib dich mit mir auf eine Reise, bei welcher du deiner Fantasie freien Lauf lassen kannst. Ich freue mich, dir das Konzept der Fantasiereise in einer entspannten Atmosphäre näherbringen zu dürfen :) LG Julian ✌🏽",
        "name": "Traumreise",
        "openEnd": false,
        "location": Locations.Himmelszelt,
        "type": GigType.Workshop,
    },
    // Lindy Hop
    {
        "id": "109",
        "startAt": "2024-06-15T11:00:00",
        "endAt": "2024-06-15T13:00:00",
        "description": "",
        "name": "Lindy Hop",
        "openEnd": false,
        "location": Locations.Spielwiese,
        "type": GigType.Workshop,
    },

    // Linolschnitt
    { 
        "id": "110",
        "startAt": "2024-06-15T12:00:00",
        "endAt": "2024-06-15T14:00:00",
        "description": "",
        "name": "Linolschnitt",
        "openEnd": false,
        "location": Locations.Werkelwinkel,
        "type": GigType.Workshop,
    },
    // Massage
    {
        "id": "111",
        "startAt": "2024-06-15T12:00:00",
        "endAt": "2024-06-15T13:00:00",
        "description": "Gönnt euch eine Auszeit und entdeckt die Kunst der Entspannung . In angenehmer Atmosphäre lernt ihr verschiedene Massage-Techniken, um Verspannungen zu lösen und das Wohlbefinden zu steigern. Tragt euch über den link in die Liste ein, die Plätze sind begrenzt:) Wir freuen uns auf euch Fredi & Helene",
        "name": "Massage",
        "openEnd": false,
        "location": Locations.SAPlaxie,
        "type": GigType.Workshop,
    },

    // Batiken
    {
        "id": "112",
        "startAt": "2024-06-15T13:00:00",
        "endAt": "2024-06-15T16:00:00",
        "description": "Keine Beschreibung",
        "name": "Batiken",
        "openEnd": false,
        "location": Locations.BatikBucht,
        "type": GigType.Workshop,
    },
    // Viva la Vulva
    {
        "id": "113",
        "startAt": "2024-06-15T13:00:00",
        "endAt": "2024-06-15T15:00:00",
        "description": "Viva la Vulva. Oder Viva la Vagina. Oder...was denn nun? In diesem Vulva-Workshop wollen wir über die Frage diskutieren, was denn nun was ist, warum immer noch welche veralteten Mythen über das weibliche Geschlecht herrschen und warum die Vulva nach wie vor so wenig Sichtbarkeit hat. Wir sprechen darüber, wie vielfältig und unterschiedlich dieses Organ eigentlich ist und was es alles kann. Und wenn ihr eure Vulva schon mal von Angesicht zu Angesicht sehen wolltet, ohne euch verbiegen zu müssen, können Personen mit Vulva zudem mit Alginat einen eigenen Abdruck ihrer Vulva anfertigen, das Negativ wird dann mit Gips ausgegossen.",
        "name": "Viva la Vulva",
        "openEnd": false,
        "location": Locations.SAPlaxie,
        "type": GigType.Workshop,
    },

    // Siebdrucken
    {
        "id": "114",
        "startAt": "2024-06-15T14:00:00",
        "endAt": "2024-06-15T17:00:00",
        "description": "",
        "name": "Siebdrucken",
        "openEnd": false,
        "location": Locations.Werkelwinkel,
        "type": GigType.Workshop,
    },
    // Akrobatik
    {
        "id": "115",
        "startAt": "2024-06-15T14:00:00",
        "endAt": "2024-06-15T15:00:00",
        "description": "",
        "name": "Akrobatik",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },
    // Traumfänger
    {
        "id": "116",
        "startAt": "2024-06-15T14:00:00",
        "endAt": "2024-06-15T16:00:00",
        "description": "",
        "name": "Traumfänger",
        "openEnd": false,
        "location": Locations.PlutoPavillon,
        "type": GigType.Workshop,
    },

    // Töpfern
    {
        "id": "117",
        "startAt": "2024-06-15T15:00:00",
        "endAt": "2024-06-15T18:00:00",
        "description": "",
        "name": "Töpfern",
        "openEnd": false,
        "location": Locations.Werkelwinkel,
        "type": GigType.Workshop,
    },
    // Pole Dance
    {
        "id": "118",
        "startAt": "2024-06-15T15:00:00",
        "endAt": "2024-06-15T16:00:00",
        "description": "Pole Dance hat mein Leben verändert! Dank ein paar Stripperinnen, die irgendwann angefangen haben, interessierten Frauen, ihre Bewegungen und Tricks an der Stange beizubringen, ist Pole Dance zu einer weltweit bekannten und beliebten Sport- und Tanzart gewachsen. Das coole am Pole Dance ist, dass es so vielfältig ist, und du dich immer wieder neu (er-)finden kannst. Sinnlicher Tanz barfuß oder in 20 cm High Heels, beeindruckende Tricks kopfüber, in 3 Metern Höhe oder Low Flow, ganz nah am Boden. An der Pole ist alles möglich. Was alle Richtungen vereint: man lernt zu schweben, indem man mit der eigenen Muskelkraft lernt, den eigenen Körper zu tragen. Meine Polenreise hat mir wahnsinnig viel Körper(selbst)bewusstsein geschenkt, was mein Leben schon lange auch außerhalb vom Pole positiv beeinflusst. Ich mache seit 2015 Pole Dance und bin seit 2020 Trainerin. Mein zu Hause ist das sexy, sinnliche und flowige Pole, ich unterrichte aber auch bis ins hohe Level Tricks und akrobatische Bewegungen. Auf der Tanzwiese habe ich eine bunte Mischung aus einfachen Tricks, Spins und tänzerischen Elementen für euch dabei. Vielleicht verlasst ihr meinen Workshop sogar mit einem stolzen Foto eures ersten Pole Tricks oder einem kurzen Video eurer ersten Bewegungen um die Pole. Da wir auf dem Festival leider nur eine Pole zur Verfügung haben, mache ich die Elemente vor und ihr könnt sie nacheinander probieren. Pole ist Team Sport, das heißt ihr könnt euch bei den Durchläufen gegenseitig unterstützen, voneinander lernen und Fotos machen :) Willkommen ist jede*r! Ohne irgendwelche Grundvorraussetzungen! Ich kann meine Tipps an das Level, das ihr mitbringt jederzeit anpassen. Aus Grip-Gründen (wir halten durch Hautkontakt an der Pole), ihr eine kurze Hose anhaben, bei der die Oberschenkel-Innenseiten frei sind, oder freigemacht werden können und am besten nicht direkt vorher eincremen ;) Ich freue mich, mit euch zu fliegen! Eure Leonie",
        "name": "Pole Dance",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },
    // Dont talk to strangers 
    {
        "id": "119",
        "startAt": "2024-06-15T16:00:00",
        "endAt": "2024-06-15T17:00:00",
        "description": "Die Tanzwiese zieht jedes Jahr neue Leute an 🐝 was dazu führt, dass sich einige von euch “noch” nicht kennen oder bisher nur wenig miteinander gesprochen haben ‼️ ✨ Das wollen wir ändern! ✨ Komme gerne am Sa & So zum 'DONT TALK to strangers' Workshop. 🃏Mit dem interaktiven Spiel SPARK von ‘Seek Discomfort’ werden wir versuchen euch aus eurer Komfortzone zu locken und einander besser kennen zu lernen 🌝",
        "name": "(Don't) talk to strangers",
        "openEnd": false,
        "location": Locations.Himmelszelt,
        "type": GigType.Workshop,
    },

    // Musik in ungeraden Takten
    {
        "id": "199",
        "startAt": "2024-06-15T15:00:00",
        "endAt": "2024-06-15T17:00:00",
        "description": "Du willst dich für ein gesellschaftliches Thema einsetzen, aber weißt noch nicht so recht, wie du damit viele Menschen erreichst? In Workshop „Banden bilden“ bringen wir unser Wissen zusammen: Wie organisiere ich eine Demo? Wie finde ich Verbündete und kommuniziere mit der Presse und Politik? Eine konkrete Idee kann gerne mitgebracht werden, ist aber keine Voraussetzung.",
        "name": "Musik in ungeraden Takten",
        "openEnd": false,
        "location": Locations.PlutoPavillon,
        "type": GigType.Workshop,
    },
     //Banden
    {
        "id": "120",
        "startAt": "2024-06-15T17:00:00",
        "endAt": "2024-06-15T18:30:00",
        "description": "Du willst dich für ein gesellschaftliches Thema einsetzen, aber weißt noch nicht so recht, wie du damit viele Menschen erreichst? In Workshop „Banden bilden“ bringen wir unser Wissen zusammen: Wie organisiere ich eine Demo? Wie finde ich Verbündete und kommuniziere mit der Presse und Politik? Eine konkrete Idee kann gerne mitgebracht werden, ist aber keine Voraussetzung.",
        "name": "Banden Bilden",
        "openEnd": false,
        "location": Locations.Himmelszelt,
        "type": GigType.Workshop,
    },
    // Female
    {
        "id": "121",
        "startAt": "2024-06-15T17:00:00",
        "endAt": "2024-06-15T18:00:00",
        "description": "Female Dance ist keine spezifische Tanzrichtung im herkömmlichen Sinne, sondern eher ein Konzept, das darauf abzielt, zu empowern. Es kann verschiedene Tanzstile umfassen, die miteinander kombiniert werden. Beim Tanzen geht es nicht darum, wie man aussieht, sondern wie man sich dabei fühlt. In diesem Workshop soll es neben dem Spaß auch um die Stärkung des Selbstvertrauens, der Ausdrucksfähigkeit und der Entfaltung der eigenen Kreativität gehen, unabhängig von Geschlechtergrenzen - hier ist jeder Mensch willkommen. Du brauchst keine Tanzerfahrung für diesen Workshop, nur Freude am Tanz und die Lust, etwas Neues auszuprobieren! Wir werden nach einem kleinen Warm-Up und ein paar Basic-Übungen eine kleine Choreographie einstudieren und zusammen tanzen! <3 Ich freue mich auf euch, Alina💫",
        "name": "Female Dance",
        "openEnd": false,
        "location": Locations.MainStage,
        "type": GigType.Workshop,
    },
    
    // Sonntag
    {
        "id": "140",
        "startAt": "2024-06-16T11:00:00",
        "endAt": "2024-06-16T12:00:00",
        "description": "",
        "name": "Meditation",
        "openEnd": false,
        "location": Locations.SAPlaxie,
        "type": GigType.Workshop,
    },
    {
        "id": "141",
        "startAt": "2024-06-16T12:00:00",
        "endAt": "2024-06-16T13:00:00",
        "description": "Gönnt euch eine Auszeit und entdeckt die Kunst der Entspannung . In angenehmer Atmosphäre lernt ihr verschiedene Massage-Techniken, um Verspannungen zu lösen und das Wohlbefinden zu steigern. Tragt euch über den link in die Liste ein, die Plätze sind begrenzt:) Wir freuen uns auf euch Fredi & Helene",
        "name": "Massage",
        "openEnd": false,
        "location": Locations.SAPlaxie,
        "type": GigType.Workshop,
    },
    {
        "id": "142",
        "startAt": "2024-06-16T13:00:00",
        "endAt": "2024-06-16T16:00:00",
        "description": "",
        "name": "Siebdruck",
        "openEnd": false,
        "location": Locations.Werkelwinkel,
        "type": GigType.Workshop,
    },
    
]


const useTimeTable = (idFilter: string[] | null = null) => {
    if (idFilter === null) return timetable

    return timetable.filter(gig => {
        return idFilter.indexOf(gig.id) >= 0
    })
}   

const useArtistGigs = (artistId?: string ) => {
    if (!artistId) return []
    return timetable.filter(gig => {
        return gig.artistId === artistId
    })
}

const useTimeTableEvent = (id?: string) => {
    if (!id) return undefined
    return timetable.filter(gig => {
        return gig.id === id
    })[0]
}

export { useTimeTable, useArtistGigs, useTimeTableEvent }