import choky from './ArtistImages/2024/choky.jpg'
import elane from './ArtistImages/2024/elane.jpg'
import joe from './ArtistImages/2024/joe-back.jpg' //
import konstrukt from './ArtistImages/2024/konstrukt-tesa.jpg'
import luxus from './ArtistImages/2024/luxus.jpg'
import montezuma from './ArtistImages/2024/montezuma.jpg'
import olympia from './ArtistImages/2024/olympia-diamanti.jpg'
import pablo from './ArtistImages/2024/pablo-froind.jpg'
import peppermint from './ArtistImages/2024/peppermint-peanuts.jpg' //
import sophti from './ArtistImages/2024/sophti.jpg'
import tarot from './ArtistImages/2024/tarot.jpg'
import solzinho from './ArtistImages/2024/sozinhoo.jpg'
import constantcrises from './ArtistImages/2024/constantcrises.jpg'
import cia from './ArtistImages/2024/cia.jpg'
import buesi3000 from './ArtistImages/2024/buesi3000.jpg'
import bella from './ArtistImages/2024/bella-biscuit.jpg'
import placeholder from './ArtistImages/2024/placeholder.jpg'



export enum SocialPlatforms {
    Spotify = 'spotify',
    SoundCloud = 'soundcloud',
    Instagram = 'instagram',
}

type Artist = {
    id: string,
    name: string,
    description?: string
    image?: string
    urls?: {
        [key in SocialPlatforms]?: string
    }
}

const artists: Artist[] = [
    {
        "id": "1",
        "name": "Joe Back",
        "image": joe,
        "description": "Joe Back ist ein House-DJ aus Mannheim und Co-Head von Koerper Records. Seine Musik reicht von Percussion-lastigem House mit Wave-Einschlägen, bis hin zu soul-vollen Disco-Melodien, die immer wieder mitreißen.",
        "urls": {
            "instagram": "https://instagram.com/joe_back",
            "soundcloud": "https://soundcloud.com/user-464192258",
            "spotify": "https://open.spotify.com/artist/2Lab68D8csyvP4YYVXvck5"
        }
    },
    {
        "id": "2",
        "name": "LUXUS",
        "image": luxus,
        "description": "rgendwo zwischen Freibadromantik und Großstadtmelancholie präsentiert die Band LUXUS ihren verträumten Indie-Pop mit wavy Gitarrenriffs und atmosphärischen Synthie- Klängen. Es darf getanzt werden. Die Hamburger Formation hat seit 2021 bereits zwei Alben veröffentlicht und hat im Jahr 2023 bei verschiedenen Live-Shows gespielt, darunter das Early Bird Open-Air Rei- chenau und das Helenviertel Sommerfest in Hamburg. LUXUS setzt sich zusammen aus Lukas am Schlagzeug, Paul mit Gesang und Rhythmusgitarre, Lorena am Synthesizer und Linus an der Leadgitarre.",
        "urls": {
            "spotify": "https://open.spotify.com/artist/3hVQuMnXItwytc9DCzHAFR",
            "instagram": "https://www.instagram.com/luxus.mp3",
        }
    },
    {
        "id": "3",
        "name": "Choky",
        "description": "Choky ist ein junges Geschwisterduo, das sich gut gelaunt und verträumt elektronischer Pop-Musik widmet. Mal verspielt wie Tom Tom Club, mal charmant daherkommend wie ein French Pop-Duo oder aber dancy wie Big Beat aus den 90ern zitieren und vermengen Choky Musikgeschichte auf ihre ganz eigene Art und schaffen so Elektro-Pop, der begeistert. Choky macht Spaß, Choky ist fresh und vor allem sehr süß.",
        "image": choky,
        "urls": {
            "instagram": "https://www.instagram.com/choky_im_ohr",
        }
    },
    {
        "id": "4",
        "name": "Büsi3000",
        "description": "Irgendwo zwischen Breakbeat und Acid, irgendwo zwischen Zürich und Hamburg finden wir Livia aka Büsi 3000 und tanzen zusammen. Alles ist erlaubt, hauptsache es mach Spass!",
        "image": buesi3000,
        "urls": {
            "instagram": "https://www.instagram.com/bizzeliviel",
        }
    },
    {
        "id": "5",
        "name": "Cia",
        "image": cia,
        "description": "CIA's Booty-Universum! Ihre Beats sind wie ein Spektakel aus rhythmischen Bewegungen, die die Tanzfläche in eine sinnliche Oase verwandeln. Mit funky Grooves und pulsierenden Bässen entführt sie dich auf eine Reise voller rhythmischer Leidenschaft. Bereit, deine Hüften zu befreien?",
        "urls": {
            "instagram": "https://www.instagram.com/cia__360",
        }
    },
    {
        "id": "6",
        "name": "Peppermint Peanuts",
        "description": "Wenn Peppermint Peanuts auf der Bühne stehen, legt sich ein Schleier aus sanften Klängen und ein Nebel krachenden Lärms über die Tanzwiese. Die Kasseler Jungs machen es sich nun zum dritten Mal auf der Wiese gemütlich und bringen die Grillen mit ihrem tanzbaren Indie-Rock zum Zirpen. Sie nehmen Euch mit auf eine Reise zwischen Meditation und Eskalation und haben Bock auf euch!",
        "image": peppermint,
        "urls": {
            "instagram": "https://www.instagram.com/peppermint_peanuts",
            "spotify": "https://open.spotify.com/artist/2uQalmuLw6hM8JJ6ZGojxl",
        }
    },
    {
        "id": "7",
        "name": "Pablo Froind",
        "description": "Tanzbar, rhythmisch und voller Energie – das sind Pablo Froind. Ihre Musik vereint Elemente aus Swing, Folk, Pop und Latin und zelebriert mit dem Publikum das Leben im Rhythmus der Nacht. Ihre Texte lassen die Zuhörenden von lauen Sommernächten träumen, in denen alles möglich scheint. Auf ihren Konzerten laden Pablo Froind dich ein, zusammen zu tanzen, zu singen und dich im Moment zu verlieren.",
        "image": pablo,
        "urls": {
            "instagram": "https://www.instagram.com/pablofroind",
            "spotify": "https://open.spotify.com/artist/7FBt7bjjB1rCmrT8YepnGX",
        }
    },
    {
        "id": "8",
        "name": "Olympia Diamanti",
        "image": olympia,
        "description": "Wenn Olympia Diamanti spielt, dann wird getanzt! Die vierköpfige Band aus dem Norden nimmt euch mit auf eine Reise durch verschiedene Genres und Emotionen. Von Indie-Pop über Rock bis hin zu melancholischen Trompetenmelodien kreieren sie ihren ganz eigenen Sound.",
        "urls": {
            "instagram": "https://instagram.com/olympia_diamanti",
            "spotify": "https://open.spotify.com/artist/4EeRGD5VD9TnJn8DAg9MdI",
        }
    },
    {
        "id": "9",
        "name": "TAROT",
        "description": "TAROT ist ein Duo (Lea Rossatti und Ivan Syrov) aus Stuttgart, welches mit elektronischen und experimentellen Klängen in unterschiedliche Genres eintaucht.  Durch Improvisation werden dabei (un-)mögliche Kombinationen und Genregrenzen entdeckt. Dabei setzen die Beiden vor allem auf das Zusammenspiel von Vocals, Synths, Samples und digitalem Chaos.",
        "image": tarot,
        "urls": {
            "instagram": "https://www.instagram.com/tarot.sounds",
        }
    },
    {
        "id": "10",
        "name": "E_Lane_",
        "image": elane,
        "description": "Durch das Tanzen entdeckte E_Lane_ ihre Liebe zur Musik, heute bringt sie die Menschen mit Deephouse und Melodic Techno in Bewegung. Jedes ihrer Sets erzählt seine eigene Geschichte mit Hilfe von verträumten Melodien, treibenden Beats und düsteren Bässen. Lasst euch mitnehmen auf eine Reise geführt von eingängigen Vocals, energetischen Build-ups und epischen Breakdowns.",
        "urls": {
            "instagram": "https://www.instagram.com/e__lane__?igsh=MXVvdjZmdXBpZjA3bg==",
        }
    },
    {
        "id": "11",
        "name": "MONTEZUMA",
        "description": "MONTEZUMA ziehen die Inspiration für ihre faszinierende Musik aus ihren Erfahrungen, der Natur und einer Vielzahl globaler Einflüsse. Freundschaft, Kreativität, elektronische Beats, der Sound von Live-Instrumenten und atmosphärischen Klängen verschmelzen zu einer lebendigen Mischung. Montezumas Tracks sind sowohl authentisch als auch zeitlos. Wir freuen uns und können jetzt schon kaum die Füße stillhalten! Das Indie-Pop Duo aus Saarbrücken schiebt seit geraumer Zeit den ein oder anderen Hit durch die Spotify-Playlisten und arbeitet kontinuierlich an kommenden Produktionen, die irgendwo zwischen 'Sonnenaufgang nach 'ner durchzechten Nacht' und 'gefühlvoll-gut-gelaunt' einzuordnen sind. Wir freuen uns auf ein warmes Sundowner-Set am Samstagabend ",
        "image": montezuma,
        "urls": {
            "instagram": "https://www.instagram.com/montezuma.ofc",
            "spotify": "https://open.spotify.com/artist/6AgAUwug3IMyaejTUjnkhU",
        }
    },
    {
        "id": "12",
        "name": "BELLA BUISCUIT",
        "description": "Im Leben von BELLA BUISCUIT treffen Kunst und Musik aufeinander. Die Karlsruher DJ steht auf Deep House, Disco und Groovy Tunes! Freut euch sie am Samstag auf der Tanzwiese zu hören!",
        "image": bella,
        "urls": {
            "instagram": "https://www.instagram.com/bellabis.cuit",
        }
    
    },
    {
        "id": "13",
        "name": "SOPHTI",
        "description": "-",
        "image": sophti,
        "urls": {
            "soundcloud": "https://on.soundcloud.com/AE7ArZ2okvsjLXoaA",
            "instagram": "https://www.instagram.com/dj.sophti",
        }
    },
    {
        "id": "14",
        "name": "SOLZINHO",
        "image": solzinho,
        "description": "",
        "urls": {
            "instagram": "https://www.instagram.com/nayellizaa?igsh=YzIxYnZuNWNzYTIz",
        }
    },
    {
        "id": "15",
        "name": "Constant Crisis",
        "image": constantcrises,
        "description": "Seid ihr bereit für basslastig-bösen Drum'n'Bass? Dann wird euch Constant Crisis mit seinem Set abholen! Von Jump-Up bis zu Neurofunk könnt ihr hier alles erwarten. Fest steht: Tanzen ist Pflicht!",
    },
    {
        "id": "16",
        "name": "Leroy Lovestein",
        "image": placeholder,
        "description": "",
    },
    {
        "id": "17",
        "name": "Urban Socks",
        "image": placeholder,
        "description": "",
    },
    {
        "id": "18",
        "name": "och.Lars",
        "image": placeholder,
        "description": "",
    },
    {
        "id": "420",
        "name": "Konstrukt b2b Tesa",
        "image": konstrukt,
        "description": "Das Hamburger DJ Duo Konstrukt und Tesa bringen euch harte, treibende Beats mit Sexappeal im Unterton.",
        "urls": {
            "soundcloud": "https://soundcloud.com/konstrukt-hh",
            "spotify": "https://open.spotify.com/artist/1vJqDoYHCd9cFqGK6ztMfP?si=fqk2y2usQtOwIZaeJaYouQ",
            "instagram": "https://www.instagram.com/konstrukt.hh/",
        }
    },
]

const useArtists: () => Artist[] = () => {
    return artists
}

const useArtist: (id?: string) => Artist | null = (id) => {
    if (!id) return null
    return artists.find(artist => artist.id === id) || null
}


export { useArtists, useArtist }
