
import abreise from './TeaserImages/abreise.jpg'
import aufbau from './TeaserImages/aufbau.jpg'
import camping from './TeaserImages/camping.jpg'
import duschen from './TeaserImages/duschen.jpg'
import food from './TeaserImages/food.jpg'
import mitmachen from './TeaserImages/mitmachen.jpg'
import muell from './TeaserImages/muell.jpg'
import musik from './TeaserImages/musik.jpg'
import nachtruhe from './TeaserImages/nachtruhe.jpg'
import parken from './TeaserImages/parken.jpg'
import rauchen from './TeaserImages/rauchen.jpg'
import workshops from './TeaserImages/workshops.jpg'
import { Button } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShowerIcon from '@mui/icons-material/Shower';
import FestivalIcon from '@mui/icons-material/Festival';
import HardwareIcon from '@mui/icons-material/Hardware';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CommuteIcon from '@mui/icons-material/Commute';
import PetsIcon from '@mui/icons-material/Pets';
import MapIcon from '@mui/icons-material/Map';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HubIcon from '@mui/icons-material/Hub';
import PianoIcon from '@mui/icons-material/Piano';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PublicIcon from '@mui/icons-material/Public';
import ShieldIcon from '@mui/icons-material/Shield';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import GavelIcon from '@mui/icons-material/Gavel';
// import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WcIcon from '@mui/icons-material/Wc';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

export type InfoElement = {
    title: string,
    anchor: string,
    content: JSX.Element | string,
    icon: JSX.Element,
    action?: JSX.Element,
    image?: string
}

const FAQ: InfoElement[] = [
    {
        title: "Anreise & Abreise",
        anchor: "anreise-abreise",
        image: abreise,
        icon: <CommuteIcon />,
        content: (
            <>
                <div>
                    Der Einlass ist am 13.06. von 13-19 Uhr und am 14.06. von 11-20 Uhr. Falls ihr früher oder später anreisen möchtet, meldet euch gerne bei uns. Die Tanzwiese findet in der Nähe von Künzelsau statt. Die genaue Adresse findet ihr auf Telegram.
                    Für alle Zugfahrenden: Der nächste Bahnhof ist in Waldenburg, Ba-Wü. Von dort geht die Fahrt mit dem Bus weiter bis zur Haltestelle “Crispenhofen Halberg Mitte” (online Fahrplan auf www.nvh.de). Falls ihr am Tag der Anreise irgendwo stecken bleiben solltet, euch auf dem Weg verirrt oder sonst einfach nicht weiterkommt, meldet euch bei uns.
                </div>
            </>
        ),
    },
    {
        title: "Aufbau & Abbau",
        anchor: "aufbau-abbau",
        image: aufbau,
        icon: <HardwareIcon />,
        content: (
            <>
                <div>
                Da wir die Tanzwiese in unserer Freizeit organisieren, freuen wir uns über Unterstützung beim Auf- und Abbau. Falls ihr Lust und Zeit habt, könnt ihr auch schon vor Beginn oder nach der Tanzwiese mit uns auf dem Gelände zelten, beim Auf-/Abbau helfen und euch kreativ auf dem Gelände austoben. Werkzeug und Materialien warten auf euch. Alle kreativen Köpfe und helfende Hände sind herzlich willkommen, zu tun gibt es immer genug.
                </div>
            </>
        ),
    },
    {
        title: "Awareness",
        anchor: "awareness",
        icon: <Diversity2Icon />,
        content: (
            <>
                <div>
               Wie ihr in unseren Regeln lesen könnt hat rassistisches, sexistisches, antisemitisches, homophobes und respektloses Verhalten auf der Tanzwiese nichts verloren. Wir wollen alle eine schöne Zeit miteinander verbringen und bitten euch daher aufeinander achtzugeben. Doch auch auf der Tanzwiese kann übergriffiges Verhalten nicht komplett ausgeschlossen werden. Daher wird es während des Festivals ein Awareness-Team geben, das euch bei jeglicher Art von Übergriffen beiseite stehen wird. Falls ihr euch unwohl fühlt, sexualisierte Gewalt oder Diskrimminierung erlebt,
wendet euch an diese Telefonnummer: 015755744812 (Letty) oder sucht eine der Tanzbienen auf (erkennbar am weißen T-Shirt mit der Biene). Sie werden euch zur Seite stehen und helfen.
</div>
            </>
        ),
        action: <Button startIcon={<LocalPhoneIcon />} variant="contained" href="tel:+4915755744593">Jetzt Anrufen!</Button>
    },
    {
        title: "Camping",
        anchor: "camping",
        image: camping,
        icon: <FestivalIcon />,
        content: (
            <>
                <div>
               Geschlafen wird auf der Campingwiese. Damit alle ein Plätzchen für ihr Zelt finden, bitten wir euch keine großen Camps zu errichten und die markierten Wege frei zu lassen. Viel Platz braucht ihr ohnehin nicht, da die Party auf der Wiese nebenan stattfindet. Wir bitten euch, eure mega batteriebetriebenen Bluetooth Musikanlagen Zuhause zu lassen und laden euch stattdessen ein, die Musik auf den Bühnen der Tanzwiese zu genießen. Ab 04:00 Uhr bis 10:00 Uhr gilt auf der Campingwiese die Nachtruhe (Siehe Nachtruhe). Nehmt euch eine Powerbank für elektronische Geräte mit, da es keine Steckdosen auf dem Festival geben wird. Falls ihr gesundheitlich auf bestimmte Mittel angewiesen seid, z.B. Strom oder einen Kühlschrank, lasst uns das bitte im Vorfeld wissen, damit wir eine Lösung finden können.
               </div>
            </>
        ),
    },
    {
        title: "Duschen",
        anchor: "duschen",
        image: duschen,
        icon: <ShowerIcon />,
        content: (
            <>
                <div>
                Neben einer Gießkannendusche gibt es auch einen Wasserhahn mit Trinkwasser auf dem Gelände. Er lässt sich öffnen UND auch wieder schließen - denkt bitte daran. In der Nähe findet ihr den schönen Tiroler See, an dem ihr euch zu jeder Zeit abkühlen und erfrischen könnt.
                </div>
            </>
        ),
    },
    {
        title: "Essen & Trinken",
        anchor: "essen-und-trinken",
        image: food,
        icon: <FastfoodIcon />,
        content: (
            <>
                <div>
                    Für eure Verpflegung auf der Tanzwiese seid vor allem ihr selbst verantwortlich. Also packt euch euer Lieblingsessen und -trinken ein, damit ihr das Wochenende energiegeladen seid. Bitte achtet darauf, wiederverwendbare Behälter und Pfandflaschen zu nutzen, um möglichst wenig Müll zu produzieren.
                    Zusätzlich bieten wir verschiedene Suppen, Pizza, Crepes und weitere Leckereien. Bitte bringt hierfür eigenes Geschirr mit. Mit verschiedenen Limos, Bier, Radler, Cocktails, Kaffee und Tee wird euch unsere Bar versorgen. Bezahlen könnt ihr mit Planeten (siehe Planeten).
                    Den nächsten Supermarkt findet ihr in Niedernhall (ca. 10 Minuten mit dem Auto).
                </div>
            </>
        ),
    },
    {
        title: "Hunde",
        anchor: "hunde",
        icon: <PetsIcon />,
        content: (
            <>
                <div>
                Ihr könnt gerne euren Hund mitbringen, müsst euch aber bewusst sein, dass ihr rund um die Uhr für ihn verantwortlich seid. Hunde müssen angeleint sein und dürfen nicht alleine über das Gelände laufen oder im Auto gelassen werden.
                </div>
            </>
        ),
    },
    {
        title: "Kleiderschrank für alle",
        anchor: "hunde",
        icon: <PetsIcon />,
        content: (
            <>
                <div>
                Falls ihr Kleider habt, denen ihr ein zweites Leben schenken wollt, bringt sie am Besten alle mit, denn dieses Jahr steht wieder unser Tanzwiesen- Schrank zum Kleidertausch bereit. Kramt gerne darin herum, vielleicht findet ihr ja ein paar Schätze.
                </div>
            </>
        ),
    },
    {
        title: "Kontakt",
        anchor: "kontakt",
        icon: <ConnectWithoutContactIcon />,
        content: (
            <>
                <div>
                Falls ihr Fragen, Ideen oder Anmerkungen habt, schreibt uns gerne eine E-Mail @festival.tanzwiese@gmail.com. In Notfällen könnt ihr uns auch immer anrufen unter 015755744593 (Thea), 015758290409 (Tabea) und 017657835792 (Paulina).
                </div>
            </>
        ),
    },
    {
        title: "Lage",
        anchor: "lage",
        icon: <MapIcon />,
        content: (
            <>
                <div>
                    Die Tanzwiese findet in der Nähe von Künzelsau in Baden-Württemberg statt. Die genaue Adresse findet ihr in unserer Telegram-Gruppe.
                </div>
            </>
        ),
    },
    {
        title: "Du willst mitmachen?",
        anchor: "mitmachen",
        image: mitmachen,
        icon: <HubIcon />,
        content: (
            <>
                <div>
                Wir freuen uns, auch für das kommende Jahr, über weitere Mitglieder:innen im Orga-/ Helfer:innen/Workshop Team, da wir gemeinsam so viel mehr schaffen können. Wir sind offen für Ideen und jede Art von Teilhabe.
                </div>
            </>
        ),
    },
    {
        title: "Müll",
        anchor: "muell",
        image: muell,
        icon: <DeleteIcon />,
        content: (
            <>
                <div>
                Müll ist schlecht - sowohl auf unserer Wiese als auch bei der Verbrennung, im Meer oder wo auch immer er landet. Wir versuchen daher unseren Verbrauch zu minimieren. Falls das mit dem komplett Vermeiden nicht klappen sollte, stellen wir
                euch sympathische Mülleimer mit verschiedenen Geschmacksrichtungen (Papier, Plastik, Restmüll) auf. Füttert sie fleißig. Und falls doch etwas mal auf der Wiese liegt, fühlt euch angesprochen, es in den Müll zu werfen. Die Pferde, auf deren Nahrung wir tanzen, werden es euch später danken.
                </div>
            </>
        ),
    },
    {
        title: "Musik",
        anchor: "musik",
        image: musik,
        icon: <PianoIcon />,
        content: (
            <>
                <div>
                    Wir haben wieder ein vielseitiges Line-Up für euch erstellt, das ihr in der App, in der <a href="https://t.me/+Hetn9Egzu6BhM2Vi">Telegram- Gruppe</a> und auf <a href="https://instagram.com/tanzwiese">Instagram</a> sehen könnt. Außerdem gibt es dieses Jahr eine Tanzwiese <a href="https://open.spotify.com/playlist/5xGuKOz2RxqIchEQYSjEXG">Playlist auf Spotify</a>. Zögert also nicht, in die diesjährigen Acts hineinzuschnuppern und euch schon mal langsam einzugrooven.
                </div>
            </>
        ),
        action: <Button startIcon={<CalendarMonthIcon />} variant="contained" href="/timetable">Timetable</Button>
    },
    {
        title: "Nachtruhe",
        anchor: "nachtruhe",
        icon: <ModeNightIcon />,
        image: nachtruhe,
        content: (
            <>
                <div>
                    Damit jede:r jeden Tag des Festivals ausgeschlafen genießen kann, bitten wir euch, die Nachtruhe zu respektieren. Das bedeutet, dass von 4 - 10Uhr morgens bitte keine Musik, laute Gespräche oder anderer Lärm die Campingwiese beschallt. Auf der Tanzwiese beginnt die Nachtruhe mit dem Ende des letzten Musik-Acts, danach könnt ihr gerne noch in gemäßigter Lautstärke quatschen.
                </div>
            </>
        ),
    },
    {
        title: "Parken",
        anchor: "parken",
        icon: <LocalParkingIcon />,
        image: parken,
        content: (
            <>
                <div>
                    Parken könnt ihr auf der Parkwiese, auf die ihr durch Schilder und Parkplatzeinweiser:innen geleitet werdet. Wir sind mitten in der Pampa, daher sind viele auf ein Auto angewiesen. Dennoch wäre es super, wenn ihr Fahrgemeinschaften bilden könntet. Nutzt dafür die <a href="https://t.me/+Hetn9Egzu6BhM2Vi">Mitfahrbörse auf Telegram</a>. Schaut also dort zuerst einmal nach, bevor ihr alle fleißig eure Autos packt.
                </div>
            </>
        ),
    },
    {
        title: "Planeten",
        anchor: "planeten",
        icon: <PublicIcon />,
        content: (
            <>
                <div>
                    Um das Hantieren mit Euros auf dem Festival zu vermeiden und damit ihr euch sorgenlos auf den Kosmos Tanzwiese einlassen könnt, werden hier Getränke und Essen nicht mit irdischem Geld sondern mit Planeten beglichen. Damit entspricht ein Planet mindestens zwei Euro (eher viel mehr!). Zwei dieser handgepressten Unikate bekommt ihr mit eurem Bändchen am Einlass Falls ihr mehr braucht, kein Problem, wendet euch an die hilfsbereiten Tanzbienen, die ihr am T-Shirt mit Biene erkennt.
                </div>
            </>
        ),
    },
    {
        title: "Privatveranstaltung",
        anchor: "privatveranstaltung",
        icon: <ShieldIcon />,
        content: (
            <>
                <div>
                    Die Tanzwiese ist eine Privatveranstaltung und nur eingeladene Gäste sind berechtigt, das Gelände zu betreten. Euch erkennen wir am Festivalbändchen, das ihr am Einlass bekommt, wenn euer Name auf der Gästeliste steht. Spontan mitgebrachte Personen haben keinen Zutritt, da wir nur eine bestimmte Anzahl an Personen auf das Gelände lassen dürfen. Bitte habt dafür Verständnis.
                </div>
            </>
        ),
    },
    {
        title: "Rauchen",
        anchor: "rauchen",
        icon: <SmokingRoomsIcon />,
        image: rauchen,
        content: (
            <>
                <div>
                    Bitte achtet darauf, dass keine Zigarettenstummeln oder -schachteln in der Wiese landen, auf denen später unsere Pferde grasen. Auf den Tischen werden Aschenbecher stehen und falls euch auffällt, dass einer fehlt, meldet euch bei den Bienen.    
                </div>
            </>
        ),
    },
    {
        title: "Regeln",
        anchor: "regeln",
        icon: <GavelIcon />,
        content: (
            <>
                <div>
                    Mit eurem Kommen willigt ihr automatisch in unsere Regeln ein, die ihr vor dem Festival auf Telegram und in der App sehen könnt. Bei Regelverstoß werdet ihr das Festivalgelände verlassen. Keine Diskussion.    
                </div>
            </>
        ),
    },
    {
        title: "Toiletten",
        anchor: "toiletten",
        image: duschen,
        icon: <WcIcon />,
        content: (
            <>
                <div>
                    Wir bitten euch, die freundlichen Komposttoiletten für all eure Geschäfte zu verwenden und nicht in den Wald zu flitzen. Auch wenn es erstmal ungewohnt erscheint, fragt euch lieber - ist es normal, sein Geschäft mit Trinkwasser wegzuspülen?
                </div>
            </>
        ),
    },
    {
        title: "Unkostenbeitrag/Die Cash-Frage",
        anchor: "workshops",
        image: workshops,
        icon: <HomeRepairServiceIcon />,
        content: (
            <>
                <div>
                Die Tanzwiese wird von uns auf ehrenamtlicher Basis organisiert und veranstaltet, lediglich die Künstler:innen bekommen Honorare. Eingenommenes Geld fließt in die Finanzierung der nächsten Tanzwiese und dient als Puffer, falls etwas kaputt geht. Spenden sind deswegen immer herzlich willkommen und ihr könnt euch sicher sein, dass diese hervorragend investiert werden: nämlich geradewegs in die Tanzwiese 2025!
                </div>
            </>
        ),
    },
    {
        title: "Workshops",
        anchor: "workshops",
        image: workshops,
        icon: <HomeRepairServiceIcon />,
        content: (
            <>
                <div>
                    Auch dieses Jahr bereiten wieder vielzählige kreative Köpfe mit sehr viel Liebe eine große Auswahl an Workshops für euch vor. Mit dabei sind dieses Jahr zum Beispiel: Siebdruck, Yoga, Akrobatik und vieles mehr. Für das Batiken und den Siebdruck-Workshop bringt gerne eigene Klamotten/Stoffe mit, die ihr upcyceln wollt.
                </div>
            </>
        ),
    },
]

export { FAQ }